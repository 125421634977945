<template>
	<div class="home" style="width: 100%; height: 100%;">
		<van-nav-bar style='margin-bottom: 20px;'/>
		<div style='display:flex; justify-content: space-around; '>
			<div>请选择一个城市……</div>
		</div>
		<van-divider :hairline='false' style='margin-bottom: 10px;' />
		<div style='display:flex; flex-wrap: wrap; justify-content: space-around; '>
			<van-button round v-for='item, i in num' @click="slayer(item)"
				:class="color[num_c[i]]">{{cz[item]}}</van-button>
		</div>
		<van-divider :hairline='false'/>
		<van-popup v-if='show_pop' v-model="lopen" style="padding: 30px; width: 80%; display:flex; flex-wrap: wrap; justify-content: space-around; " @closed='closed'>
			<van-button round v-for='item, i in nu'
				:class="i == m ? 'button-magenta' : 'button-blue' ">{{cz[item]}}</van-button>
			<div style='display:flex; justify-content: space-around; '>
				<van-button plain round icon="replay" type="default" @click="lafresh"/>
			</div>
		</van-popup>
		<div style='display:flex; justify-content: space-around; '>
			<van-button plain round icon="replay" type="default" @click="refresh"/>
		</div>
		<van-nav-bar style='position: absolute; bottom:0px; width:100%;' />
	</div>
</template>

<script>
	export default {
		name: 'HomeView',
		data() {
			return {
				show_pop: true,
				speed: 0,
				num: [],
				num_c:[],
				nu: [],
				m:0,
				id: 0,
				SHAKE_THRESHOLD: 4000,
				lastUpdate: 0,
				X: 0,
				y: 0,
				z: 0,
				lastx: 0,
				lasty: 0,
				lastz: 0,

				lopen: false,
				az: ['安　庆', '安　阳', '鞍　山', '鄂　州', '防城港', '佛　山', '福　州', '抚　顺', '哈尔滨', '海　口', '汉　中', '杭　州', '合　肥', '河　源',
					'鹤　壁', '鹤　岗', '黑　河', '衡　水', '衡　阳', '葫芦岛', '湖　州', '怀　化', '淮　北', '淮　南', '淮　阴', '黄　冈', '黄　山', '黄　石',
					'惠　州', '开　封', '昆　明', '莱　芜', '兰　州', '廊　坊', '乐　山', '连云港', '辽　阳', '辽　源', '聊　城', '柳　州', '六　安', '六盘水',
					'龙　岩', '娄　底', '泸　州', '洛　阳', '漯　河', '马鞍山', '茂　名', '梅　州', '绵　阳', '牡丹江', '南　昌', '南　充', '南　京', '南　宁',
					'南　平', '南　通', '南　阳', '内　江', '宁　波', '台　州', '太　原', '泰　安', '泰　州', '唐　山', '天　津', '天　水', '铁　岭', '通　化',
					'通　辽', '铜　川', '铜　陵', '威　海', '潍　坊', '渭　南', '温　州', '乌　海', '无　锡', '芜　湖', '吴　忠', '梧　州', '武　汉', '西　安',
					'西　宁', '咸　宁', '咸　阳', '湘　潭', '襄　樊', '孝　感', '新　乡', '新　余', '信　阳', '邢　台', '厦　门', '徐　州', '许　昌', '烟　台',
					'延　安', '盐　城', '扬　州', '阳　江', '阳　泉', '伊　春', '宜　宾', '宜　昌', '益　阳', '银　川', '鹰　潭', '营　口', '永　州', '榆　林',
					'玉　林', '玉　溪', '岳　阳', '云　浮', '枣　庄', '湛　江', '张家界', '张家口', '漳　州', '镇　江', '郑　州', '中　山', '舟　山', '珠　海',
					'株　洲', '自　贡', '遵　义'
				],
				bz: ['白　城', '白　山', '白　银', '包　头', '宝　鸡', '保　定', '北　海', '北　京', '本　溪', '沧　州', '常　德', '常　州', '巢　湖', '潮　州',
					'郴　州', '成　都', '承　德', '长　春', '长　沙', '长　治', '赤　峰', '重　庆', '达　州', '大　连', '大　庆', '大　同', '丹　东', '德　阳',
					'德　州', '东　莞', '东　营', '赣　州', '广　元', '广　州', '贵　港', '贵　阳', '桂　林', '鸡　西', '吉　林', '济　南', '济　宁', '佳木斯',
					'嘉　兴', '嘉峪关', '江　门', '焦　作', '揭　阳', '金　昌', '金　华', '锦　州', '晋　城', '荆　门', '荆　州', '景德镇', '九　江', '宿　迁',
					'宿　州', '攀枝花', '盘　锦', '平顶山', '萍　乡', '莆　田', '秦皇岛', '青　岛', '清　远', '泉　州', '日　照', '三门峡', '三　明', '三　亚',
					'汕　头', '汕　尾', '商　丘', '上　海', '韶　关', '邵　阳', '绍　兴', '深　圳', '沈　阳', '十　堰', '石家庄', '石嘴山', '双鸭山', '朔　州',
					'四　平', '松　原', '苏　州', '遂　宁'
				],
				color: ['button-green', 'button-blue', 'button-magenta', 'button-tea', 'button-yellow', 'button-orange',
					'button-light', 'button-grey', 'button-dark', 'button-red'
				],
			}
		},
		methods: {
			closed(){
				this.show_pop=false;
				this.$nextTick(()=>{
					this.show_pop=true;
				})
			},
			async test(iemi) {
				let ret
				if (iemi) ret = await this.$ajax.apost("iemi=" + iemi + "&mod=11")
				if (ret > 0) {
					await this.$ajax.apost("iemi="+iemi+"&mod=12&cmod=" + (Number(ret) - 1))
					this.show();
				} else {
					alert('无访问权限');
					window.location.href = "https://www.baidu.com";
				}
			},
			show() {
				if (window.DeviceMotionEvent) window.addEventListener('devicemotion', this.deviceMotionHandler, false);
				this.refresh();
			},
			deviceMotionHandler(eventData) {	
				let acceleration = eventData.accelerationIncludingGravity;
				let curTime = new Date().getTime();

				if (curTime - this.lastUpdate > 30) {
					let diffTime = curTime - this.lastUpdate;
					this.lastUpdate = curTime;
					this.x = acceleration.x;
					this.y = acceleration.y;
					this.z = acceleration.z;
					let speed = Math.abs(this.x + this.y + this.z - this.lastx - this.lasty - this.lastz) / diffTime * 10000;
					if (speed > this.SHAKE_THRESHOLD) { //摇一摇灵敏度
						if (this.lopen) this.lafresh();
						else this.refresh(); // Do something
					}

					this.lastx = this.x;
					this.lasty = this.y;
					this.lastz = this.z;
				}
			},
			refresh() {
				this.num = [];
				this.num_c = [];
				while (this.num.length < 24) {
					var n = Math.floor(Math.random() * this.cz.length + 1) - 1;
					if (this.num.indexOf(n) == -1) {
						this.num.push(n);
						this.num_c.push(Math.floor(Math.random() * this.color.length + 1)-1)
					}
				}
			},
			slayer(item) {
				this.id = item
				this.lafresh()
				
				this.lopen = true;
			},

			lafresh() {
				this.m = Math.floor(Math.random() * 12 + 1) - 1;
				let n
				this.nu = [];

				while (this.nu.length < 12) {
					if (this.id >= this.az.length) n = Math.floor(Math.random() * this.az.length + 1) - 1;
					else n = Math.floor(Math.random() * this.bz.length + 1) - 1 + this.az.length;
					if (this.nu.indexOf(n) == -1) this.nu.push(n);
				}
				this.nu[this.m] = this.id
			}
		},
		created() {
			this.cz = this.az.concat(this.bz);
			var url = location.search; //获取url中"?"符后的字串
			if (url.indexOf("?") != -1) var str = url.substr(1);
			this.test(str)
		},
	}
</script>
